@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    font-family: 'Inter', sans-serif;
}

.send {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

@layer base {
    .message-item {
        @apply max-w-[85%];
    }
    .message-sender {
        @apply ml-auto text-sm rounded-xl rounded-tr-none py-2 px-3;
    }
    .message-receiver {
        @apply mr-auto w-fit whitespace-pre-wrap text-sm rounded-xl rounded-tl-none py-2 px-3;
    }
    .message-file-sender{
        @apply text-sm py-3 px-3 flex justify-center items-center h-screen;
    }
    .message-rond-download{
        @apply rounded-tr-none flex justify-center items-center h-screen;
    }
    .context-menu-item {
        @apply flex gap-2 items-center justify-start w-full hover:bg-indigo-200 dark:hover:bg-gray-600 px-3 py-1 whitespace-nowrap;
    }

    .login_input {
        @apply py-1 px-2 outline-none bg-transparent text-black dark:text-white rounded-md w-full;
    }
    .login_formgroup {
        @apply px-2 py-1 outline-none bg-transparent border border-gray-200 dark:border-gray-700 rounded-md w-full my-3;
    }
    .login_formgroup.focused {
        @apply ring ring-green-500;
    }
    .snaps-inline {
        scroll-snap-type: inline mandatory;
        scroll-padding: 0.25rem;
        grid-auto-columns: calc(100% / 4);
    }
    .snaps-inline--child > * {
        scroll-snap-align: start;
    }
    .contains-content {
        contain: content;
    }

    .scrollbox {
        overflow-y: auto;
        visibility: hidden;
    }
    .scrollbox-content,
    .scrollbox:hover,
    .scrollbox:focus {
        visibility: visible;
    }
    
    .scrollbox_delayed {
        transition: visibility 0.2s ;
    }
    
    .scrollbox_delayed:hover {
        transition: visibility 0s 0.2s;
    }
    
    /* width */
    *::-webkit-scrollbar {
        @apply w-2;
    }
    
    /* Track */
    *::-webkit-scrollbar-track {
        @apply rounded-[10px];
    }
    
    /* Handle */
    *::-webkit-scrollbar-thumb {
        @apply bg-gray-400 dark:bg-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600 rounded-[14px];
    }

    .chatbox-input {
        @apply  break-all;
    }
    .chatbox-input img {
        @apply w-fit inline;
    }
    .mentioned_people {
        @apply cursor-text;
    }
    .mentioned_people.bold {
        @apply font-bold cursor-pointer;
    }
    .at_mention {
        @apply font-normal text-indigo-400 outline-none;
    }
    .emoji {
        @apply mx-[1px] !w-5 !h-5 cursor-text;
    }

    /* color theme */
    ._default {
        @apply bg-gradient-to-r from-emerald-500 to-emerald-700 dark:from-emerald-700 dark:to-emerald-800;
    }
    
    ._sky {
        @apply bg-gradient-to-r from-sky-500 to-sky-600 dark:from-sky-600 dark:to-sky-700;
    }
    
    ._indigo {
        @apply bg-gradient-to-r from-indigo-500 to-indigo-600 dark:from-indigo-700 dark:to-indigo-800;
    }
    
    ._rose {
        @apply bg-gradient-to-r from-rose-500 to-rose-600 dark:from-rose-700 dark:to-rose-800;
    }
    
    ._pink {
        @apply bg-gradient-to-r from-pink-500 to-pink-600 dark:from-pink-700 dark:to-pink-800;
    }
    
    ._violet {
        @apply bg-gradient-to-r from-violet-500 to-violet-600 dark:from-violet-700 dark:to-violet-800;
    }
    
    ._blue {
        @apply bg-gradient-to-r from-blue-500 to-blue-600 dark:from-blue-700 dark:to-blue-800;
    }
    
    ._lime {
        @apply bg-gradient-to-r from-lime-600 to-lime-700 dark:from-lime-700 dark:to-lime-800;
    }

    ._orange {
        @apply bg-gradient-to-r from-orange-500 to-orange-600 dark:from-orange-700 dark:to-orange-800;
    }
    
    ._amber {
        @apply bg-gradient-to-r from-amber-500 to-amber-600 dark:from-amber-700 dark:to-amber-800;
    }
    ._yellow {
        @apply bg-gradient-to-r from-yellow-500 to-yellow-500 dark:from-yellow-700 dark:to-yellow-800;
    }
    
    ._g-pinkindigo {
        @apply bg-gradient-to-r from-pink-500 to-indigo-600 dark:from-pink-700 dark:to-indigo-800;
    }

    ._g-yellowrose {
        @apply bg-gradient-to-r from-yellow-500 to-rose-600 dark:from-yellow-600 dark:to-rose-800;
    }
    
    ._g-rosepurple {
        @apply bg-gradient-to-r from-rose-500 to-purple-600 dark:from-rose-700 dark:to-purple-800;
    }

    ._g-yellowteal {
        @apply bg-gradient-to-r from-sky-500 to-emerald-600 dark:from-sky-500 dark:to-emerald-700;
    }

    ._g-skyblue {
        @apply bg-gradient-to-r from-sky-500 to-indigo-600 dark:from-sky-500 dark:to-indigo-700;
    }

    /* typing ball */
    .typing_ball {
        @apply h-[8px] w-[8px] rounded-full bg-gray-400 dark:bg-gray-500 border border-slate-100 dark:border-gray-700;
    }
    aside {
        @apply !bg-white dark:!bg-slate-900 !border-none
    }
    aside div > input {
        @apply !text-black !bg-gray-200 placeholder:!text-slate-400 !border-gray-300
        dark:!text-white dark:!bg-gray-950 dark:!border-gray-800;
    }
    aside li > div {
        @apply !bg-transparent !text-gray-500 dark:!text-white;
    }
    aside li > div button:hover {
        @apply !bg-red-400;
    }

    button {
        @apply !select-none;
    }


    /* profile bg color theme */
    .prof_red {
        @apply bg-gradient-to-b from-red-100 bg-red-200 text-red-600 ring-2 ring-red-200;
    }
    .prof_blue {
        @apply bg-gradient-to-b from-blue-100 bg-blue-200 text-blue-600 ring-2 ring-blue-200;
    }
    .prof_sky {
        @apply bg-gradient-to-b from-sky-100 bg-sky-200 text-sky-600 ring-2 ring-sky-200;
    }
    .prof_green {
        @apply bg-gradient-to-b from-green-100 bg-green-200 text-green-600 ring-2 ring-green-200;
    }
    .prof_indigo {
        @apply bg-gradient-to-b from-indigo-100 bg-indigo-200 text-indigo-600 ring-2 ring-indigo-200;
    }
    .prof_orange {
        @apply bg-gradient-to-b from-orange-100 bg-orange-200 text-orange-600 ring-2 ring-orange-200;
    }
    .prof_purple {
        @apply bg-gradient-to-b from-purple-100 bg-purple-200 text-purple-600 ring-2 ring-purple-200;
    }
    .prof_violet {
        @apply bg-gradient-to-b from-violet-100 bg-violet-200 text-violet-600 ring-2 ring-violet-200;
    }
    .prof_yellow {
        @apply bg-gradient-to-b from-yellow-50 bg-yellow-200 text-yellow-600 ring-2 ring-yellow-300;
    }
    .prof_gray {
        @apply bg-gradient-to-b from-gray-100 bg-gray-200 text-gray-600 ring-2 ring-gray-200;
    }

    .status_yellow {
        @apply bg-yellow-500;
    }
    .status_green {
        @apply bg-green-500;
    }
    .status_red {
        @apply bg-red-500;
    }

    .user-profile {
        @apply w-full h-full rounded-full;
    }
}
